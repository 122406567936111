.video-recommendation-section {
  width: 100%;
  margin-bottom: 3rem;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(15rem,1fr));
  grid-row-gap: 1em;
  grid-column-gap: 2em;
}

.video-grid-element {
  position: relative;
  box-shadow: -1px 4px 11px 0 rgb(0 0 0 / 30%);
  transition: transform 0.2s ease-in-out;

  .image-container {
    aspect-ratio: 16/9;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease-in-out;
    }
  }
}

.video-grid-element:hover {
  transform: scale(1.02);
}



.video-recommendation-section-title {
  margin-bottom: 2rem;
}

.max-lines {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}