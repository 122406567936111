
$max-width-content: 1200px;
$body-background-color: #fafafa;

:root {
  --header-height: 60px;
  --side-view-width: 400px;
  --video-list-item-height: 80px;
  --default-padding: 10px;
}



*, *::before, *::after {
  box-sizing: border-box;
}
body {
  background-color: $body-background-color;
  width: 100vw;
}

a {
  text-decoration: none;
}

button {
  position: relative;
  color: steelblue;
  font-size: 0.9rem;
  font-family: inherit;
  padding: 0.5rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  background: none;
  transition: background-color 0.3ms ease-in-out;
}

button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ebebeb;
  border-radius: 5px;
  transition: opacity 0.3ms ease-in-out;
  opacity: 1;
  z-index: -1;
}

button[disabled] {
  color: grey;
  cursor: initial;
  background-color: $body-background-color;
}

header {
  position: relative;
  width: 100vw;
  height: var(--header-height);
  padding: 10px;
  background-color: white;
  box-shadow: 0 2px 13px 0 rgb(0 0 0 / 20%);
  margin-bottom: 1rem;
}

.header-flex-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.youtube-year-selector {
  border: none;
  border-bottom: 2px solid black;
  color: black;
  font-size: 2rem;
  border-radius: 0;
  font-weight: bold;
  margin-bottom: -0.25rem;
  background-color: $body-background-color;
}

.youtube-year-selector-label {
  margin-right: 0.5rem;
  font-size: 2em;
  font-weight: bold;
}

.youtube-year-selector-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.user-avatar {
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
  overflow: hidden;
  border-radius: 100px;
  margin-right: 0.25rem;
}

.user-avatar.large {
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;

}

.header-user-profile-link {
  height: 30px;
  width: 30px;
}

.auth-information {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logo-and-label-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Signika", sans-serif;
  font-weight: 400 !important;
}

.web-site-title {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: 400;
}

.App-logo {
  height: 100%;
  object-fit: contain;
  border-radius: 100px;
  overflow: hidden;
  margin-right: 0.5rem;
}

main {
  max-width: $max-width-content;
  margin: 0 auto 300px auto;
  width: 100%;
  padding: 0.5rem;
}

.secondary-tab {
  padding: 0 10px;
}

.video-list {
  padding: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  max-height: 320px;
  border: 1px solid lightgray;
  box-sizing: border-box;
}

.video-list-item:last-child {
  border-bottom: none;
}



.videoplayer {
  aspect-ratio: 16/9;
  width: 100%;
  height: 100%;
}

.creator-info-box {
  background-color: #dbdbcc;
  padding: 10px;
  width: 100%;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

@-moz-keyframes spin {
  100% { -webkit-transform: rotate(90deg); transform:rotate(90deg); }
}
@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(90deg); transform:rotate(90deg); }
}
@keyframes spin {
  100% { -webkit-transform: rotate(90deg); transform:rotate(90deg); }
}

.rotate-90 {
  transform: rotate(90deg);
}

.creator-info-box-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 80px;
}

.creator-info-box-header img {
  height: 100%;
  width: 80px;
  object-fit: cover;
  margin-right: 5px;
  border-radius: 25px;
  border: 3px solid white;
  box-shadow: 0 0 0 1px rgb(198 198 198);
}


.creator-info-box-body {
  font-size: 0.95rem;
}

.creator-info-box-channel-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

input {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 5px;
  font-size: 1.5rem;
  line-height: 20px;
  transition: border-color 0.3s ease-in-out;
}

input:hover {
  border-color: aqua;
}

.tab-element {
  box-sizing: border-box;
  padding: var(--default-padding);
  border: 1px solid lightgray;
  width: 100%;
  margin-top: 10px;
}

.video-statistics-primary-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.video-statistics-secondary-row {
  font-size: 0.9rem;
  font-style: italic;
}

#login-to-rate-tooltip {
  display: none;
}

.rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.video-list-item {
  height: var(--video-list-item-height);
  width: 100%;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px dotted lightgray;

  .video-list-item-picture {
    aspect-ratio: 16/9;
    height: 100%;
    cursor: pointer;
    margin-right: 5px;
    object-fit: cover;
  }

  .video-list-item-description {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.watch-video-view {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 5rem;
}

.black-link {
  color: black;
}

#full-stars-example-two {

  display: inline;

  /* use display:inline-flex to prevent whitespace issues. alternatively, you can put all the children of .rating-group on a single line */
  .rating-group {
    display: inline-flex;
  }

  /* make hover effect work properly in IE */
  .rating__icon {
    pointer-events: none;
  }

  /* hide radio inputs */
  .rating__input {
    position: absolute !important;
    left: -9999px !important;
  }

  /* hide 'none' input from screenreaders */
  .rating__input--none {
    display: none
  }

  /* set icon padding and size */
  .rating__label {
    padding: 0 0.1em;
    font-size: 1.5rem;
  }

  /* set default star color */
  .rating__icon--star {
    color: #d30000;
  }

  /* if any input is checked, make its following siblings grey */
  .rating__input:checked ~ .rating__label .rating__icon--star {
    color: #ddd;
  }
  .rating-group:not(.disabled):hover .rating__label{
    cursor: pointer;
  }


  /* make all stars orange on rating group hover */
  .rating-group:not(.disabled):hover .rating__label .rating__icon--star {
    color: red;
  }

  /* make hovered input's following siblings grey on hover */
  .rating__input:not(.disabled):hover ~ .rating__label .rating__icon--star {
    color: #ddd;
  }
}

.video-thumbnail-renderer-overlay  {
  position: relative;
  aspect-ratio: 16/9;
  width: 100%;
  overflow: hidden;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }

  img:hover {
    transform: scale(1.05);
  }

  .image-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, black 100%);
  }

  .image-overlay:hover {
  }



  .video-thumbnail-details {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    padding: 0.375rem;
    text-shadow: black 0px 1px 2px;
  }

  .video-length-and-title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .video-length {
    background-color: rgba(213, 213, 213, 0.56);
    border-radius: 3px;
    padding: 0 0.125rem;
    max-width: 6ch;
  }

  .video-thumbnail-title {

  }

  .channel-title {
    font-size: 0.75rem;
  }
}

@media only screen and (min-width: 767px) {
  .video-grid-element-hero {
    grid-column: span 2;
    grid-row: span 2;
  }

  main {
    padding: 2rem;
  }
}

@media only screen and (min-width: 900px) {
  .watch-video-view {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr var(--side-view-width);
  }

  .primary-tab {
    padding-left: 10px;
  }


}









