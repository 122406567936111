.comment-section {
  width: 100%;
}

.comment-section-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .page-number {
    margin: 0 2rem;
  }
}

.comment-creation-form {
  margin-bottom: 2rem;
}

.comment-creation-form > textarea {
  width: 100%;
  height: 80px;
  display: block;
  resize: none;
  font-family: inherit;
  font-size: 1rem;
  padding: 0.375rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  border: 1px solid lightgray;

}

.comment {
  font-size: 0.9rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;

  .comment-timestamp {
    font-size: 0.8rem;
    color: #777777;
  }

  .comment-body {
    margin-top: 0.5rem;
  }

  .user-avatar {
    margin-right: 0.5rem;
  }
}

.comment-section-header .comments-count{
  font-weight: 100;
  font-size: 1rem;
}

.comment-section-header-title {
  font-size: 1.25rem;
}
